import React from "react";
import "../../src/custom.css";
import Profile from '../Images/profile.png';
import Logout from "./Logout";
export default function Dropdown() {
    return (
        <>
            <div className="dropdown_component">
                <button className="dropbtn "><img src={Profile}  alt="profile"/></button>
                <div className="dropdown-content">
                    <p >Admin</p>
                    <Logout/>
                    {/* <Link to={pageRoutes.passwordChange}><p>Change Password</p></Link> */}
                </div>
            </div>
        </>
    )
}