import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, InputAdornment, IconButton } from '@mui/material';
import SideBar from "../components/Sidebar";
import { Card, CardContent } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import "../../src/custom.css";
import Logout from "../components/Logout";
import Dropdown from "../components/dropdown"
import apiFunctions from '../apiKit/api';
import Swal from 'sweetalert2';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isOpen, setIsopen] = useState(true);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleOpen = () => {
        setIsopen(!isOpen);
    }
   

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleValidation = () => {
        let tempErrors = {};
        // tempErrors.currentPassword = validatePassword(currentPassword) ? "" : "Invalid current password. It must be at least 8 characters long, include at least one number and one special character.";
        tempErrors.newPassword = validatePassword(newPassword) ? "" : "Invalid new password. It must be at least 8 characters long, include at least one number and one special character.";
        tempErrors.confirmPassword = newPassword === confirmPassword ? "" : "Passwords do not match";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            var json = {
                "confirmpassword": currentPassword,
                "password": newPassword,
            }
            apiFunctions.changepasswordApi(json).then((res) => {
                if (res.status === 200) {
                    console.log("success");
                    Swal.fire({
                        text: res.message,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.clear();
                            window.location.replace("/");
                        }
                    });
                } else {
                    Swal.fire({
                        text: res.message,
                        icon: 'error'
                    });
                }
            }).catch((err) => {
                Swal.fire({
                    text: 'An error occurred. Please try again later.',
                    icon: 'error'
                });
            });
        }
    };


    const togglePasswordVisibility = (setShowPassword, showPassword) => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className='row'>
                            <div className="d-flex w-100 justify">
                                <IconButton className="web-btn" onClick={handleOpen} >
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown/>
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        <div className="row mt-3 main">
                            <div className="col-12">
                                <Card elevation={0} className='p-3'>
                                    <CardContent>
                                        <div className="container bg-light p-2 my-5 rounded shadow">
                                            <div className="text-start"><h4 className="fw-bold">Change Password</h4></div>

                                            <form onSubmit={handleSubmit}>                                              
                                                <Box mb={3}>
                                                    <TextField
                                                        label="Current Password"
                                                        variant="outlined"
                                                        type={showCurrentPassword ? "text" : "password"}
                                                        fullWidth
                                                        value={currentPassword}
                                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                                        error={!!errors.currentPassword}
                                                        helperText={errors.currentPassword}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => togglePasswordVisibility(setShowCurrentPassword, showCurrentPassword)}
                                                                        edge="end"
                                                                    >
                                                                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                                <Box mb={3}>
                                                    <TextField
                                                        label="New Password"
                                                        variant="outlined"
                                                        type={showNewPassword ? "text" : "password"}
                                                        fullWidth
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                        error={!!errors.newPassword}
                                                        helperText={errors.newPassword}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => togglePasswordVisibility(setShowNewPassword, showNewPassword)}
                                                                        edge="end"
                                                                    >
                                                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                                <Box mb={3}>
                                                    <TextField
                                                        label="Confirm New Password"
                                                        variant="outlined"
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        fullWidth
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        error={!!errors.confirmPassword}
                                                        helperText={errors.confirmPassword}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => togglePasswordVisibility(setShowConfirmPassword, showConfirmPassword)}
                                                                        edge="end"
                                                                    >
                                                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                                <Button className="btn" variant="contained" color="primary" type="submit" >
                                                    Change Password
                                                </Button>
                                            </form>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
