import axios from "axios";
import { appConstants } from './appConstant'

// server url
const baseURL = appConstants.baseUrl

const APIKit = axios.create({ baseURL, timeout: 60000000, });
APIKit.interceptors.request.use(async (config) => {

    const token = localStorage.getItem("teapoy-token") || sessionStorage.getItem("teapoy-token")

    if (token) config.headers["Authorization"] = `${token}`;
    config.headers["tag"] = "admin";
    return config;
});

export default APIKit;