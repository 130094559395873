import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { Button, ButtonGroup, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "../../src/custom.css";
import Table from "../components/Table";
import Loader from "../components/Loader";
import Dropdown from "../components/dropdown";
import { useSelector } from 'react-redux';
import ReduxComponent from "../components/reduxData";

const Reports = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [userData, setUserData] = useState([]);
    const [videoData, setVideoData] = useState([]);

    const { reportUserdata, reportVideodata } = useSelector((state) => state.api);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        console.log("post reports", reportUserdata);
        console.log("video reports", reportVideodata);

        setUserData(reportUserdata);
        setVideoData(reportVideodata);
    }, [reportUserdata, reportVideodata]);


    const handleOpen = () => {
        setIsopen(!isOpen);
    };


    const handleViewOpen = (row) => {
        console.log(row, "row");

        setSelectedRow(row);
        setIsViewOpen(true);
    };


    const handleClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };


    const columns = [
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                if (!params.value) return '-';
                const date = new Date(params.value);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                });
            }
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <IconButton onClick={() => handleViewOpen(params.row)}><VisibilityIcon /></IconButton>
                    </ButtonGroup >
                );
            }
        },
    ];


    const postColumns = [
        {
            field: 'email',
            headerName: "Reporter Email",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'creater',
            headerName: "Creator Email",
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        // {
        //     field: 'post_url',
        //     headerName: 'Video',
        //     flex: 1,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     renderCell: (params) => {
        //         const videoUrl = params.value;
        //         return (
        //             <video>
        //                 <source src={videoUrl} type="video/mp4" />
        //             </video>
        //         );
        //     },
        // },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                if (!params.value) return '-';
                const date = new Date(params.value);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                });
            }
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <IconButton onClick={() => handleViewOpen(params.row)}><VisibilityIcon /></IconButton>
                    </ButtonGroup >
                );
            }
        },
    ];


    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className="row">
                            <div className="d-flex w-100 justify">
                                <IconButton className="web-btn" onClick={handleOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown />
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="row p-3 mt-3 main">
                                <div className="col-12">
                                    <Card>
                                        <CardContent>
                                            <div className="d-flex justify mb-3">
                                                <h4 className="fw-bold">Post Reports</h4>
                                            </div>
                                            <Table rows={videoData} columns={postColumns} />
                                        </CardContent>
                                    </Card>

                                    <Card className="mt-3">
                                        <CardContent>
                                            <div className="d-flex justify mb-3">
                                                <h4 className="fw-bold">User Reports</h4>
                                            </div>
                                            <Table rows={userData} columns={columns} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            {/* View Modal */}
            <Dialog className="p-3" open={isViewOpen} onClose={handleClose} fullWidth={true} maxWidth="sm" >
                <DialogTitle>View Detail</DialogTitle>
                <DialogContent>
                    <table className="table table">
                        <tbody>
                            <tr>
                                <td> <p><strong>{selectedRow?.creater ? "Reporter Email" : "Email"}:</strong></p></td>
                                <td> <p>{selectedRow?.email}</p></td>
                            </tr>
                            {selectedRow?.creater ?
                                <tr>
                                    <td> <p><strong>Creator Email:</strong></p></td>
                                    <td> <p>{selectedRow?.creater}</p></td>
                                </tr>
                                :
                                ""}
                            <tr>
                                <td> <p><strong>Message:</strong></p></td>
                                <td> <p>{selectedRow?.message
                                    ? selectedRow?.message
                                    : "-"}</p></td>
                            </tr>
                            {selectedRow?.post_url ?
                                <tr>
                                    <td> <p><strong>Video:</strong></p></td>
                                    <td>
                                        <video autoPlay style={{ width: "270px", height: "auto" }}>
                                            <source src={selectedRow?.post_url} type="video/mp4" />
                                        </video>
                                    </td>
                                </tr>
                                : ''}

                            <tr>
                                <td> <p><strong>Created At:</strong></p></td>
                                <td>
                                    <p>
                                        {selectedRow?.createdAt ?
                                            new Date(selectedRow.createdAt).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })
                                            : "-"
                                        }
                                    </p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="btn" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <ReduxComponent />
        </>
    );
};

export default Reports;
