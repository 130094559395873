import React, { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../src/custom.css";
import Logout from "../../components/Logout";
import Loader from "../../components/Loader";
import Dropdown from "../../components/dropdown";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import apiFunctions from "../../apiKit/api";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchAPIs } from "../../redux/slice/slice";

const TermsEdit = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [editorContent, setEditorContent] = useState(``);
    const { termsdata } = useSelector((state) => state.api);
    const [edit, setEdit] = useState(false);

    const dispatch = useDispatch();

    const [TermsEditContent] = useState(`
        <p><strong>Terms and conditions</strong></p><br/>
        <p>Your Privacy is important to us. It is [Your Company Name]'s policy to respect your TermsEdit regarding any information we may collect from you across our website, <a href="http://yourwebsite.com">http://yourwebsite.com</a>, and other sites we own and operate.</p><br/>
        <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p><br/>
        <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p><br/>
        <p>We don’t share any personally identifying information publicly or with third parties, except when required to by law.</p><br/>
        <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective TermsEdit policies.</p><br/>
        <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p><br/>
        <p>Your continued use of our website will be regarded as acceptance of our practices around TermsEdit and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p><br/>
        <p>This policy is effective as of [Date].</p>
    `);

    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    const handleCancel = () => {
        setEditorContent(TermsEditContent);
    }

    const handleSubmit = () => {
        console.log("Submitted content:", editorContent);
        apiFunctions.updatetermsApi({ "content": editorContent }, termsdata[0]?._id).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
            setEditorContent(termsdata[0].content);
        }, 2000);
    }, [termsdata]);

    return (
        <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
            <div className="row">
                <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                    <SideBar />
                </div>
                <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} PrivacyEdit_card main_layout`}>
                    <div className='row'>
                        <div className="d-flex w-100 justify sticky-top">
                            <IconButton className="web-btn" onClick={handleOpen}>
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                {/* <Logout /> */}
                                <Dropdown />
                            </div>
                        </div>
                    </div>
                    {/* Page Contents */}
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="row p-lg-4 p-3 mt-3 main">
                            <div className="col-12">
                                <div className="d-flex justify">
                                    <h4>Edit Terms and conditions</h4>
                                </div>
                                <Card elevation={6} className="mt-3" >
                                    <CardContent>
                                        <ReactQuill value={editorContent} onChange={(content) => {
                                            setEditorContent(content);
                                            setEdit(true);
                                        }} />
                                        <div className="mt-3 d-flex justify-content-start">
                                            <Button variant="contained" className="grey-btn" color="secondary" onClick={handleCancel} style={{ marginRight: "10px" }}>
                                                Cancel
                                            </Button>
                                            <Button disabled={edit ? false : true} className="btn" variant="contained" color="primary" onClick={handleSubmit}>
                                                Submit
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
        </div>
    );
};

export default TermsEdit;
